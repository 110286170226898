<template>
  <ClientOnly>
    <a
      v-if="!installed && !cancelled"
      @click="installPwa"
      class="cursor-pointer col-span-full flex gap-4 items-center rounded-xl bg-gradient-to-tr from-orange-300/30 to-yellow-300/40 hover:from-orange-300/50 hover:to-yellow-300/60"
      :class="{
        'p-2 w-full': type === 'small',
        'p-6 w-full md:w-fit': type !== 'small'
      }"
    >
      <ComputerDesktopIcon
        class="text-white"
        :class="{
          'h-[2.5rem] w-[2.5rem]': type === 'small',
          'h-[4rem] w-[4rem]': type !== 'small'
        }"
      />

      <div class="text-left">
        <h2
          class="text-zinc-100 font-bold"
          :class="{
            'text-xl md:text-2xl': type !== 'small',
            'text-lg': type === 'small'
          }"
        >
          安裝貓罐子
        </h2>
        <p class="text-zinc-300" :class="{ 'text-sm': type === 'small' }">{{ installed ? '已經下載囉' : '下載網頁應用程式' }}</p>
      </div>

      <button class="flex-none text-white ml-auto rounded-full p-1 bg-yellow-300/20" @click.stop="cancelInstallPwa">
        <XMarkIcon
          :class="{
            'size-6': type === 'small',
            'size-8': type !== 'small'
          }"
        />
      </button>
    </a>
  </ClientOnly>
</template>

<script setup>
import { ComputerDesktopIcon, XMarkIcon } from '@heroicons/vue/24/outline'

const { type } = defineProps({
  type: {
    type: String,
    default: ''
  }
})

const pwa = useNuxtApp().$pwa

const installed = computed(() => pwa?.isPWAInstalled)
const cancelled = computed(() => !pwa?.showInstallPrompt)

const installPwa = () => {
  console.log('installing PWA')
  pwa.install()
}

const cancelInstallPwa = () => {
  console.log('cancelling PWA installation')
  pwa.cancelInstall()
}
</script>
